import { useMe } from './useMe';

export const useIsSignedIn = () => {
    const { isValidating, mutate, error, data: me } = useMe();

    return {
        data: error === undefined,
        isValidating,
        mutate,
        error,
        me,
    };
};
