import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

export type RealTime = {
    realTimeDate: number | null;
    isRealtime: boolean;
    interval: number;
};

const initialState: RealTime = {
    realTimeDate: null,
    isRealtime: false,
    interval: 1,
};

export const realtimeSliceVisualization = createSlice({
    name: 'realtimeVisualisation',
    initialState,
    reducers: {
        setIsRealtime: (state, action: PayloadAction<boolean>) => {
            state.isRealtime = action.payload;
        },
        setRealTimeDate: (state, action: PayloadAction<number>) => {
            state.realTimeDate = action.payload;
        },
        setIntervalRealtimeGraph: (state, action: PayloadAction<number>) => {
            state.interval = action.payload;
        },
    },
});

export const selectRealtimeVisualization = (state: RootState) =>
    state.realtimeVisualization;

// Slice action creators
export const {
    setIsRealtime,
    setRealTimeDate,
    setIntervalRealtimeGraph,
} = realtimeSliceVisualization.actions;

export default realtimeSliceVisualization.reducer;
