import House3D from 'src/components/House3D/House3D';
import { GradientKeyViewThermometer } from 'src/views/objects/GradientKeyHumidity';
import { GradientKey } from 'src/models/GradientKey';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';
import { GradientConclusionView } from '../GradientConclusion';

export interface HumidityData {
    sensorFunctionType: number;
    sensorFunctionTypeIndex: number;
    absoluteHumidity: number;
    controlState: number;
}

interface BalancingVisualizationProps {
    setTableView: any;
    sensorIds: number[];
    objectId: number;
    setSensorIds: (ids: number[]) => void;
    referenceTemperature: number;
    setReferenceTemperature: (temp: number) => void;
    transformedData?: any[];
    controlStateId: number;
    threshold: number;
    gradientKey: GradientKey;
    humidityData: HumidityData[];
    humidityLevel: number;
    viewMode: string[];
    data: V3D_Object;
    error: any;
}

function BalancingVisualization(props: BalancingVisualizationProps) {
    const {
        controlStateId,
        objectId,
        referenceTemperature,
        sensorIds,
        setSensorIds,
        setTableView,
        transformedData,
        threshold,
        gradientKey,
        humidityData,
        humidityLevel,
        viewMode,
        data,
        error,
    } = props;

    const isHumidity =
        !!viewMode &&
        viewMode.length === 1 &&
        viewMode.some((option) => option.toLowerCase() === 'humidity');

    return (
        <div className="h-full">
            <House3D
                onNavigateToTable={() => setTableView('table')}
                selectedSensors={sensorIds}
                objectId={objectId}
                onSensorSelected={(id) => {
                    setSensorIds([...sensorIds, id]);
                }}
                referenceTemperature={referenceTemperature}
                sensors={transformedData || []}
                onSensorDeselected={(id) => {
                    setSensorIds([
                        ...sensorIds.filter((sensorId) => sensorId !== id),
                    ]);
                }}
                stateId={controlStateId}
                tolerance={threshold}
                gradientKey={gradientKey}
                humidityData={humidityData}
                humidityLevel={humidityLevel}
                viewMode={viewMode}
                data={data}
                error={error}
            />
            {viewMode &&
            viewMode.some((option) => option.toLowerCase() === 'conclusion') ? (
                <div
                    className="absolute top-0 left-0 flex flex-col gap-4 z-10"
                    style={{
                        filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                    }}
                >
                    <GradientConclusionView />
                </div>
            ) : (
                <div
                    className="absolute top-0 left-0 flex flex-col gap-4 z-10"
                    style={{
                        filter: 'drop-shadow(0 0 0.25rem #ffffff)',
                        marginLeft: '10px',
                    }}
                >
                    <GradientKeyViewThermometer
                        unit={isHumidity ? undefined : '°C'}
                        gradientKey={gradientKey}
                    />
                </div>
            )}
        </div>
    );
}

export default BalancingVisualization;
