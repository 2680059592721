// realtimeTemperatureSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

type RealtimeTemperaturesState = any[];

const initialState: RealtimeTemperaturesState = [];

export const realtimeTemperaturesSlice = createSlice({
    name: 'realtimeTemperatures',
    initialState,
    reducers: {
        setRealtimeTemperatures: (state, action: PayloadAction<any>) => {
            return [...action.payload];
        },
    },
});

export const selectRealtimeTemperatures = (state: RootState) =>
    state.realtimeTemperatures;

export const { setRealtimeTemperatures } = realtimeTemperaturesSlice.actions;
export default realtimeTemperaturesSlice.reducer;
