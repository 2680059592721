import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserCorporationsState {
    userCorporations: number[];
}

const initialState: UserCorporationsState = {
    userCorporations: [],
};

const userCorporationsSlice = createSlice({
    name: 'userCorporations',
    initialState,
    reducers: {
        setSelectedCorporations: (state, action: PayloadAction<number[]>) => {
            state.userCorporations = [...action.payload];
        },
        clearSelectedCorporations: (state) => {
            state.userCorporations = [];
        },

        addToExistingCorporations: (state, action: PayloadAction<number[]>) => {
            const selectedKeysSet = new Set(state.userCorporations);
            action.payload.forEach((key) => selectedKeysSet.add(key));
            state.userCorporations = Array.from(selectedKeysSet);
        },

        excludeExistingCorporations: (
            state,
            action: PayloadAction<number[]>,
        ) => {
            const selectedKeysSet = new Set(state.userCorporations);
            action.payload.forEach((key) => selectedKeysSet.delete(key)); // Use delete to exclude keys
            state.userCorporations = Array.from(selectedKeysSet);
        },
    },
});

export const {
    setSelectedCorporations,
    clearSelectedCorporations,
    addToExistingCorporations,
    excludeExistingCorporations,
} = userCorporationsSlice.actions;
export const selectSelectedKeys = (state: {
    userCorporations: UserCorporationsState;
}) => state.userCorporations.userCorporations;
export default userCorporationsSlice.reducer;
