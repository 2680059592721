import { Card } from 'antd';
import { useState } from 'react';
import House3D from 'src/components/House3D/House3DNew';
import useViewport from 'src/hooks/generic/useViewport';
import { useAverageTemperaturesWithControlState } from 'src/hooks/objects/useAverageTemperaturesWithControllState';
import { useBalance } from 'src/hooks/objects/useBalance';
import { useObject } from 'src/hooks/objects/useObject';
import { useSensorAverage } from 'src/hooks/objects/useSensorAvergage';
import { TemperatureWithControlState } from 'src/models/Temperature';
import { formatDate } from 'src/utils/formatDate';
import { tickGenerator } from '../sites/FlowTemperatureView';
import { generateGradientKey, gradients } from 'src/utils/gradients';
import { useQueryParams, withDefault, NumberParam } from 'use-query-params';
import { GradientKey } from 'src/models/GradientKey';
import { ControlState } from 'src/models/ControlState';
import { useDispatch } from 'react-redux';
import { addToExistingRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';

export interface BalanceData {
    object: string;
    sensorName: string;
    indoorTemp: number;
    indoorTempAverage: number;
    indoorTempMin: number;
    deltaAverageAbs: number;
    deltaAverage: number;
    indoorTempMax: number;
    noisePos: number;
    noiseNeg: number;
    rc: number;
    deltaMedian: number;
    sensorId: number;
    id: number;
    isActive: 'TRUE' | 'FALSE';
}

interface BalancingVisualizationProps {
    setTableView: any;
    sensorIds: number[];
    objectId: number;
    setSensorIds: (ids: number[]) => void;
    referenceTemperature: number;
    setReferenceTemperature: (temp: number) => void;
    transformedData: any[];
    controlStateId: number;
    threshold: number;
    gradientKey: GradientKey;
}

function BalancingVisualization(props: BalancingVisualizationProps) {
    const {
        controlStateId,
        objectId,
        referenceTemperature,
        sensorIds,
        setReferenceTemperature,
        setSensorIds,
        setTableView,
        transformedData,
        threshold,
        gradientKey,
    } = props;

    return (
        <div className="h-full house" style={{ height: '600px' }}>
            <House3D
                onNavigateToTable={() => setTableView('table')}
                selectedSensors={sensorIds}
                objectId={objectId}
                onSensorSelected={(id) => {
                    setSensorIds([...sensorIds, id]);
                }}
                referenceTemperature={referenceTemperature}
                setReferenceTemperature={setReferenceTemperature}
                sensors={transformedData}
                onSensorDeselected={(id) => {
                    setSensorIds([
                        ...sensorIds.filter((sensorId) => sensorId !== id),
                    ]);
                }}
                stateId={controlStateId}
                tolerance={threshold}
                gradientKey={gradientKey}
            />
        </div>
    );
}

interface BalancingViewProps {
    objectId: number;
    controlStates: ControlState[];
}

function BalancingView(props: BalancingViewProps) {
    const { objectId, controlStates } = props;
    const { screen } = useViewport();
    const defaultControlStateId =
        controlStates[controlStates.length - 1].state_order;

    const [params] = useQueryParams({
        controlId: withDefault(NumberParam, defaultControlStateId),
    });

    const [controlStateId] = useState(params.controlId);

    const [tableView, setTableView] = useState<'graph' | 'table'>('graph');

    console.log(tableView);

    const { data: object } = useObject(objectId);

    const balanceDates = {
        start: formatDate(
            new Date(controlStates[controlStateId].time_period_start),
        ),
        stop:
            controlStates[controlStateId].time_period_stop !== null
                ? formatDate(
                      new Date(controlStates[controlStateId].time_period_stop),
                  )
                : formatDate(new Date()),
    };

    const { data: balancingData } = useBalance(
        objectId,
        balanceDates?.start,
        balanceDates?.stop,
        controlStateId,
    );
    const [threshold] = useState(1);
    const {
        sensors: sensorAverages,
        selectedRealtimeSensors: sensorIds,
    } = useSensorAverage(objectId, controlStateId);
    const dispatch = useDispatch();
    const getSensorShortName = (sensorName: string) => {
        if (sensorName.length > 20) {
            return `...${sensorName.slice(sensorName.length - 20)}`;
        }

        return sensorName;
    };
    let transformedData = balancingData?.map((v) => {
        return {
            ...v,
            name: object?.name,
            sensorName:
                v.sensorName !== null ? v.sensorName : v.sensorId.toString(),
            shortSensorName:
                v.sensorName !== null
                    ? getSensorShortName(v.sensorName)
                    : v.sensorId.toString(),
            indoorTemp: v.indoorTemp.toFixed(1),
            deltaAverage: v.deltaAverage.toFixed(1),
            deltaAverageAbs: v.deltaAverageAbs.toFixed(1),
            indoorTempAverage: v.indoorTempAverage.toFixed(1),
            indoorTempMax: v.indoorTempMax.toFixed(1),
            indoorTempMin: v.indoorTempMin.toFixed(1),
            deltaMedian: v.deltaMedian.toFixed(1),
            noisePos: v.noisePos?.toFixed(1),
            noiseNeg: v.noiseNeg?.toFixed(1),
            rc: v.rc?.toFixed(1),
            sensorId: v.sensorId,
            isActive: v.isActive === 'TRUE',
        };
    });
    const {
        data: averageTemperatures,
    } = useAverageTemperaturesWithControlState(objectId, controlStateId);
    const [balanceTemp] = useState<TemperatureWithControlState | undefined>();
    const averageRefrence = (balanceTemp ?? averageTemperatures)?.data
        .map((v) => {
            const obj1 = {
                fromTemperature: v.fromTemperature,
                average: v.toTemperature,
                Mean:
                    threshold !== 0
                        ? [
                              (v.toTemperature - threshold).toFixed(1),
                              (v.toTemperature + threshold).toFixed(1),
                          ]
                        : v.toTemperature.toFixed(1),
            };
            const obj2: Record<string, any> = {};
            sensorAverages.forEach((avg) => {
                obj2[avg.sensorId.toString()] = avg?.data
                    .find((a: any) => a?.fromTemperature === v.fromTemperature)
                    ?.toTemperature.toFixed(1);
            });

            return Object.assign(obj1, obj2);
        })
        ?.sort((a, b) => a.fromTemperature - b.fromTemperature);
    const [referenceTemperature, setReferenceTemperature] = useState(
        averageRefrence?.[0]?.fromTemperature ?? NaN,
    );
    const allSensors = sensorAverages.flatMap((e) =>
        e.data.map((t) => t.toTemperature),
    );

    const calculateDomain = (array: number[]) => {
        if (array.length === 0) {
            return [15, 25];
        }

        const min = Math.floor(Math.min(...array)) - 1;
        const max = Math.ceil(Math.max(...array)) + 1;

        const domain = [min > 15 ? 15 : min, max < 25 ? 25 : max];

        return domain;
    };

    let xTicks = tickGenerator(averageRefrence ?? [], 1);
    if (xTicks.length > 1) {
        xTicks = xTicks.slice(1, xTicks.length - 1);
    }

    const yDomain = calculateDomain(allSensors);

    let yTicks = tickGenerator(
        yDomain.map((value) => ({ fromTemperature: value })),
        1,
    );
    if (yTicks.length > 1) {
        yTicks = yTicks.slice(1, yTicks.length);
    }

    const avg =
        0.1 *
        Math.round(
            10 *
                (averageTemperatures?.data.find(
                    (e) => e.fromTemperature === referenceTemperature,
                )?.toTemperature ?? 21),
        );

    const gradientKey = generateGradientKey(
        { high: avg + threshold, low: avg - threshold },
        gradients.thermometer.mix,
    );

    return (
        <>
            <div className="w-full h-full flex flex-col">
                <div className="flex w-full justify-between flex-wrap flex-col h-full">
                    <div
                        className="grid flex-1"
                        style={{
                            gridTemplateColumns:
                                screen > 1600
                                    ? 'minmax(110ch, 55vw) 1fr'
                                    : '1fr',
                        }}
                    >
                        <Card className="h-full">
                            <BalancingVisualization
                                controlStateId={controlStateId}
                                objectId={objectId}
                                referenceTemperature={referenceTemperature}
                                sensorIds={sensorIds}
                                setReferenceTemperature={
                                    setReferenceTemperature
                                }
                                setSensorIds={(sensorIds) =>
                                    dispatch(
                                        addToExistingRealtimeSensors(sensorIds),
                                    )
                                }
                                setTableView={setTableView}
                                transformedData={transformedData}
                                threshold={threshold}
                                gradientKey={gradientKey}
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BalancingView;
