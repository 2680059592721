import {
    RealTimeDataPoint,
    RealTimeResponse,
} from 'src/hooks/objects/useRealTime';

function getMax(arr: number[]) {
    let len = arr.length;
    let max = -Infinity;

    while (len--) {
        max = arr[len] > max ? arr[len] : max;
    }
    return max;
}

function getMin(arr: number[]) {
    let len = arr.length;
    let max = Infinity;

    while (len--) {
        max = arr[len] < max ? arr[len] : max;
    }
    return max;
}

export function minMaxValue(
    data: RealTimeResponse[] | undefined,
    offset: number,
    data1?: RealTimeResponse[] | undefined,
) {
    const flat: RealTimeDataPoint[] = [];
    let vals: number[] = [];
    if (data) {
        for (const entry of data) {
            flat.push(...entry.data);
        }

        vals = flat.map((v) => v.value);
    }
    if (data1) {
        for (const entry of data1) {
            flat.push(...entry.data);
        }
        vals = vals.concat(flat.map((v) => v.value));
    }
    const arr = [getMin(vals) - offset, getMax(vals) + offset];
    return arr;
}

export function minMaxValueWhole(
    data: RealTimeResponse[] | undefined,
    offset: number,
    data1?: RealTimeResponse[] | undefined,
) {
    const flat: RealTimeDataPoint[] = [];
    let vals: number[] = [];
    if (data) {
        for (const entry of data) {
            flat.push(...entry.data);
        }

        vals = flat.map((v) => v.value);
    }
    if (data1) {
        for (const entry of data1) {
            flat.push(...entry.data);
        }
        vals = vals.concat(flat.map((v) => v.value));
    }

    const arr = [
        Math.floor(getMin(vals) - offset),
        Math.round(getMax(vals) + offset),
    ];
    return arr;
}

export function minMaxArrValue(
    offset: number,
    dataArray: any[][] = [],
): number[] {
    const minArray: number[] = [];
    const maxArray: number[] = [];

    dataArray.forEach((realTimeResponses) => {
        if (realTimeResponses) {
            realTimeResponses.forEach((realTimeResponse) => {
                const values = [
                    ...realTimeResponse.data.map((entry: any) => entry.value),
                ];
                minArray.push(Math.min(...values));
                maxArray.push(Math.max(...values));
            });
        }
    });

    const minValue = Math.floor(Math.min(...minArray));
    const maxValue = Math.round(Math.max(...maxArray));

    return [minValue - offset, maxValue + offset];
}
