import React from 'react';
import { Site } from 'src/models/Site';
import { useNavigate } from 'react-router';
import Nav from 'src/utils/nav/Nav';
import './SuggestionList.scss';

interface SuggestionListProps {
    sites: Site[];
    searchQuery: string;
    setSearchQuery: (query: string) => void;
}

const SuggestionList: React.FC<SuggestionListProps> = ({
    sites,
    searchQuery,
}) => {
    const history = useNavigate();

    const suggestions = sites.reduce<
        {
            id: number | null;
            name: string;
            siteName: string;
            objectName: string;
            siteId: number;
        }[]
    >((current, site) => {
        site.objects?.forEach((obj) => {
            if (obj) {
                const name = `${site.name?.toLowerCase()} ${obj.name?.toLowerCase()}`;
                if (
                    searchQuery === '' ||
                    name.includes(searchQuery.toLowerCase())
                ) {
                    current.push({
                        id: obj.id,
                        name,
                        siteName: site.name,
                        objectName: obj.name,
                        siteId: site.id,
                    });
                }
            }
        });
        if (!site.objects?.length) {
            if (
                searchQuery === '' ||
                site.name.toLowerCase().includes(searchQuery.toLowerCase())
            ) {
                current.push({
                    id: null,
                    name: `${site.name?.toLowerCase()}`,
                    siteName: site.name,
                    objectName: '',
                    siteId: site.id,
                });
            }
        }
        return current;
    }, []);

    return (
        <div
            style={{ maxHeight: '60vh', minHeight: '60vh', overflowY: 'auto' }}
        >
            {!!suggestions.length ? (
                suggestions.map((suggestion, index) => (
                    <div
                        key={index}
                        style={{
                            padding: '8px',
                            background: '#fff',
                            border: '1px solid #ddd',
                            marginBottom: '4px',
                        }}
                    >
                        <div
                            onClick={() =>
                                history(
                                    Nav.build({
                                        path: '/sites/objects/:siteId',
                                        routeParams: {
                                            siteId: suggestion.siteId,
                                        },
                                        queryParams: {},
                                    }),
                                )
                            }
                            style={{
                                display: 'inline-block',
                                cursor: 'pointer',
                                marginRight: '8px',
                            }}
                            className="suggestion-link"
                        >
                            {`${suggestion.siteName?.trim()}`}
                        </div>
                        {!!suggestion.objectName && (
                            <div
                                onClick={() =>
                                    history(
                                        Nav.build({
                                            path: '/analytics/:objectId',
                                            routeParams: {
                                                objectId: suggestion.id ?? -1,
                                            },
                                            queryParams: {},
                                        }),
                                    )
                                }
                                style={{
                                    display: 'inline-block',
                                    cursor: 'pointer',
                                }}
                                className="suggestion-link"
                            >
                                {suggestion.objectName?.trim()}
                            </div>
                        )}
                    </div>
                ))
            ) : (
                <div style={{ padding: '8px', color: '#999' }}>
                    No suggestions available
                </div>
            )}
        </div>
    );
};

export default SuggestionList;
