import {
    Box,
    OrbitControls,
    PerspectiveCamera,
    useHelper,
    Text,
} from '@react-three/drei';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
//import * as geolib from "geolib";
import * as geolib from 'geolib';

import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';

import {
    V3D_Block,
    V3D_Floor,
    V3D_Object,
    V3D_Temperatures,
    V3D_Template,
} from './types';

import './chart.css';
import { GradientKey } from 'src/models/GradientKey';
import {
    generateGradientKey,
    getGradientColorForHumidity,
    getGradientColorForTemperature,
    getGradientColorForConclusion,
    getGradientColorForRealtimeVisualization,
    gradients,
} from 'src/utils/gradients';
import { HumidityData } from 'src/views/objects/Balancing/BalancingView';
import { selectRealtimeVisualization } from 'src/store/reducers/realTimeVisualization';
import { selectRealtimeTemperatures } from 'src/store/reducers/realtimeTemperturesSlice';
import { selectRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import {
    selectCameraSettings,
    setCameraSettings,
} from 'src/store/reducers/cameraSettingsSlice';
import { useDispatch, useSelector } from 'react-redux';

import {
    addToExistingRealtimeSensor,
    deleteExistingRealtimeSensor,
} from 'src/store/reducers/selectedRealtimeSensorsSlice';

interface ChartProps {
    objects: V3D_Object[];
    onSensorSelected: (id: number, selected: boolean) => void;
    selectedSensors: number[];
    referenceTemperature?: number;
    tolerance?: number;
    onPointerOver: (id: number, leave: boolean) => void;
    continuousSpin: boolean;
    onObjectPointerOver?: (object?: V3D_Object) => void;
    gradientKey?: GradientKey;
    humidityData?: HumidityData[];
    humidityLevel?: number;
    viewMode?: string[];
    height?: string;
}

function geoToXY(
    origin: { longitude: number; latitude: number },
    target: { longitude: number; latitude: number },
) {
    const accuracy = 0.001;

    const xDistance = geolib.getPreciseDistance(
        origin,
        {
            latitude: origin.latitude,
            longitude: target.longitude,
        },
        accuracy,
    );

    const yDistance = geolib.getPreciseDistance(
        origin,
        {
            latitude: target.latitude,
            longitude: origin.longitude,
        },
        accuracy,
    );

    const x = target.longitude > origin.longitude ? xDistance : -xDistance;
    const y = target.latitude > origin.latitude ? yDistance : -yDistance;

    return { x, y };
}

function calcAngleWithGeo(
    start: { longitude: number; latitude: number },
    stop: { longitude: number; latitude: number },
) {
    //x is adjacent and y is opposite
    //x and y together specifies which quadrant
    const { x, y } = geoToXY(start, stop);

    let result = Math.atan(y / x);

    if (result < 0) {
        result += Math.PI / 2;
    }

    if (x < 0 && y >= 0) {
        result += Math.PI / 2;
    } else if (x < 0 && y < 0) {
        result += Math.PI;
    } else if (x >= 0 && y < 0) {
        result += Math.PI + Math.PI / 2;
    }

    return result;
}

const ArrowWithDirection = (props: { color: string; space?: number }) => {
    // Define dimensions and position of the arrow

    const headRadius = 0.3;
    const headHeight = 0.6;
    const bodyRadius = 0.15;
    const bodyHeight = 2.5;

    const { color, space } = props;
    const length = space ? space : 0;

    return (
        <>
            {/* Head of the arrow (cone) */}
            <mesh position={[0, bodyHeight / 2 + headHeight / 2 + length, 0]}>
                <coneGeometry args={[headRadius, headHeight, 32]} />
                <meshBasicMaterial color={color} />
            </mesh>
            {/* Body of the arrow (cylinder) */}
            <mesh position={[0, length, 0]}>
                <cylinderGeometry
                    args={[bodyRadius, bodyRadius, bodyHeight, 32]}
                />
                <meshBasicMaterial color={color} />
            </mesh>
        </>
    );
};

const RenderFloorModel = React.memo(
    (props: {
        templates: V3D_Template[];
        averageTemperatures: V3D_Temperatures[];
        block: V3D_Block;
        floor: V3D_Floor;
        shouldIndexRender: boolean;
        floorTotHeight: number;
        center: { latitude: number; longitude: number };
        referenceTemperature: number;
        selectedSensors: number[];
        tolerance?: number;
        humidityData?: HumidityData[];
        humidityLevel?: number;
        viewMode?: string[];
        gradientKey: GradientKey;
        onSensorSelected: (id: number, selected: boolean) => void;
        onPointerOver?: (id: number, leave: boolean) => void;
    }) => {
        const {
            templates,
            block,
            floor,
            floorTotHeight,
            center,
            averageTemperatures,
            referenceTemperature,
            //onSensorSelected,
            selectedSensors,
            tolerance,
            gradientKey,
            shouldIndexRender,
            humidityData,
            humidityLevel,
            viewMode,
        } = props;

        //get width in meters
        const blockLength = geolib.getPreciseDistance(
            { latitude: block.leftCornerLat, longitude: block.leftCornerLong },
            {
                latitude: block.rightCornerLat,
                longitude: block.rightCornerLong,
            },
            0.1,
        );

        //get correct template
        const template = templates.find(
            (template) => template.templateId === floor.templateId,
        );

        //get X and Y coordinates in meters
        const { x, y } = geoToXY(center, {
            latitude: block.leftCornerLat,
            longitude: block.leftCornerLong,
        });

        // console.log("x and y :", x, y, floorTotHeight, "block.id",block.id);

        //get angle of house in radians
        //	borde min vinkel utgå från centrum på något sätt?
        const angle = calcAngleWithGeo(
            { latitude: block.leftCornerLat, longitude: block.leftCornerLong },
            {
                latitude: block.rightCornerLat,
                longitude: block.rightCornerLong,
            },
        );

        // console.log("angle", angle, block.id);

        // const angleTest = calcAngleWithXY(
        // 	{ x: x, y: y },
        // 	geoToXY(center, {
        // 		latitude: block.rightCornerLat,
        // 		longitude: block.rightCornerLong,
        // 	})
        // );
        // console.log("angles", angleTest, angle, block.id);
        //fake depth in meters

        // console.log("dimensions", block.id, blockLength, block.width);

        const opacity = selectedSensors.length === 0 ? 0.3 : 0.2;
        const minFloorNumber = Math.min(
            ...block.floors.map((floor) => floor.floorNumber),
        );

        function calculateDirection(
            lat1: number,
            lon1: number,
            lat2: number,
            lon2: number,
        ) {
            const dLon = ((lon2 - lon1) * Math.PI) / 180;
            const y = Math.sin(dLon) * Math.cos((lat2 * Math.PI) / 180);
            const x =
                Math.cos((lat1 * Math.PI) / 180) *
                    Math.sin((lat2 * Math.PI) / 180) -
                Math.sin((lat1 * Math.PI) / 180) *
                    Math.cos((lat2 * Math.PI) / 180) *
                    Math.cos(dLon);
            let brng = (Math.atan2(y, x) * 180) / Math.PI;
            brng = (brng + 360) % 360; // normalize to a compass bearing in degrees
            return brng;
        }

        const direction = calculateDirection(
            block.leftCornerLat,
            block.leftCornerLong,
            block.rightCornerLat,
            block.rightCornerLong,
        );

        // Calculate average absolute humidity if humidity data exists
        let averageAbsoluteHumidity: any = undefined;
        if (humidityData && humidityData.length > 0) {
            const totalAbsoluteHumidity = humidityData.reduce(
                (sum, data) => sum + data.absoluteHumidity,
                0,
            );
            averageAbsoluteHumidity =
                totalAbsoluteHumidity / humidityData.length;
        }

        const createDashedLinesForWalls = (
            width: any,
            height: any,
            depth: any,
        ) => {
            const lines: any = [];

            // Define vertices for each edge
            const edges = [
                // Bottom edges
                [
                    [-width / 2, -height / 2, -depth / 2],
                    [-width / 2, -height / 2, depth / 2],
                ],
                [
                    [-width / 2, -height / 2, depth / 2],
                    [width / 2, -height / 2, depth / 2],
                ],
                [
                    [width / 2, -height / 2, depth / 2],
                    [width / 2, -height / 2, -depth / 2],
                ],
                [
                    [width / 2, -height / 2, -depth / 2],
                    [-width / 2, -height / 2, -depth / 2],
                ],
                // Vertical edges
                [
                    [-width / 2, -height / 2, -depth / 2],
                    [-width / 2, height / 2, -depth / 2],
                ],
                [
                    [-width / 2, -height / 2, depth / 2],
                    [-width / 2, height / 2, depth / 2],
                ],
                [
                    [width / 2, -height / 2, depth / 2],
                    [width / 2, height / 2, depth / 2],
                ],
                [
                    [width / 2, -height / 2, -depth / 2],
                    [width / 2, height / 2, -depth / 2],
                ],
                // Connecting edges
                [
                    [-width / 2, -height / 2, -depth / 2],
                    [-width / 2, height / 2, -depth / 2],
                ],
                [
                    [-width / 2, -height / 2, depth / 2],
                    [-width / 2, height / 2, depth / 2],
                ],
                [
                    [width / 2, -height / 2, depth / 2],
                    [width / 2, height / 2, depth / 2],
                ],
                [
                    [width / 2, -height / 2, -depth / 2],
                    [width / 2, height / 2, -depth / 2],
                ],
                // top edges

                [
                    [-width / 2, height / 2, -depth / 2],
                    [-width / 2, height / 2, depth / 2],
                ],
                [
                    [-width / 2, height / 2, depth / 2],
                    [width / 2, height / 2, depth / 2],
                ],
                [
                    [width / 2, height / 2, depth / 2],
                    [width / 2, height / 2, -depth / 2],
                ],
                [
                    [width / 2, height / 2, -depth / 2],
                    [-width / 2, height / 2, -depth / 2],
                ],
            ];
            // Create dashed lines for each edge
            edges.forEach(([start, end]) => {
                lines.push([start, end]);
            });

            return lines;
        };

        let gradientKeyForHumidity: GradientKey | undefined = undefined;

        if (averageAbsoluteHumidity) {
            const high = averageAbsoluteHumidity * (1 + (humidityLevel ?? 0));
            const low = averageAbsoluteHumidity * (1 - (humidityLevel ?? 0));

            gradientKeyForHumidity = generateGradientKey(
                { high, low },
                gradients.thermometer.mix,
            );
        }

        const { isRealtime, realTimeDate } = useSelector(
            selectRealtimeVisualization,
        );
        const selectedRealtimeSensors: any = useSelector(selectRealtimeSensors);
        const realtimeTemperatures = useSelector(selectRealtimeTemperatures);
        const dispatch = useDispatch();

        return (
            <group
                onPointerOver={() => {}}
                onPointerOut={() => {}}
                position={[x, block.zOffset, -y]}
                rotation={[0, angle, 0]}
                key={block.id}
            >
                <mesh>
                    {template!.spaces.map((space) => {
                        const spaceSizeX = space.sizeX * blockLength;
                        const spacePosX = space.posX * blockLength;
                        const spaceSizeY = space.sizeY * block.width;
                        const spacePosY = space.posY * block.width;

                        const datapoint = block.datapoints?.find(
                            (datapoint) =>
                                datapoint.floorId === floor.floorId &&
                                datapoint.spaceNumber === space.spaceNumber,
                        );

                        let isHumid = false;
                        let isDry = false;
                        let value: number | undefined = undefined;
                        if (
                            datapoint &&
                            datapoint.sensorFunctionTypeIndex &&
                            humidityLevel !== undefined &&
                            viewMode &&
                            (!!viewMode.some(
                                (option) => option.toLowerCase() === 'humidity',
                            ) ||
                                !!viewMode.some(
                                    (option) =>
                                        option.toLowerCase() === 'conclusion',
                                ))
                        ) {
                            const row = humidityData?.find(
                                (humidity) =>
                                    humidity.sensorFunctionTypeIndex ===
                                    datapoint.sensorFunctionTypeIndex,
                            );
                            if (row) {
                                value = row.absoluteHumidity;
                                if (
                                    value >
                                    averageAbsoluteHumidity *
                                        (1 + humidityLevel)
                                ) {
                                    //eslint-disable-next-line
                                    isHumid = true;
                                } else if (
                                    value <
                                    averageAbsoluteHumidity *
                                        (1 - humidityLevel)
                                ) {
                                    //eslint-disable-next-line
                                    isDry = true;
                                }
                            }
                        }

                        const getColorFromGradient = () => {
                            if (
                                viewMode &&
                                !!(viewMode.length === 1) &&
                                viewMode.some(
                                    (option) =>
                                        option.toLowerCase() === 'humidity',
                                ) &&
                                humidityData &&
                                value &&
                                gradientKeyForHumidity
                            ) {
                                return space.spaceNumber === 0
                                    ? 0x000000
                                    : datapoint
                                    ? getGradientColorForHumidity(
                                          humidityData,
                                          value,
                                          gradientKeyForHumidity,
                                      )
                                    : 0xffffff;
                            } else {
                                return space.spaceNumber === 0
                                    ? 0x000000
                                    : datapoint
                                    ? getGradientColorForTemperature(
                                          datapoint.temperatures,
                                          averageTemperatures,
                                          referenceTemperature,
                                          gradientKey,
                                          tolerance,
                                      )
                                    : 0xffffff;
                            }
                        };

                        const getFinalColor = (
                            humidityValue: number | undefined,
                        ) => {
                            if (
                                isRealtime ||
                                (viewMode &&
                                    viewMode.some(
                                        (option) =>
                                            option.toLowerCase() === 'realtime',
                                    ))
                            ) {
                                return space.spaceNumber === 0
                                    ? 0x000000
                                    : datapoint
                                    ? getGradientColorForRealtimeVisualization(
                                          realtimeTemperatures.find(
                                              (item) =>
                                                  item.id === datapoint.id,
                                          )?.data || [],
                                          gradientKey,
                                          realTimeDate,
                                      )
                                    : 0xffffff;
                            }
                            if (
                                viewMode &&
                                !!viewMode.some(
                                    (option) =>
                                        option.toLowerCase() === 'conclusion' &&
                                        datapoint &&
                                        gradientKey &&
                                        gradientKeyForHumidity &&
                                        humidityData &&
                                        humidityValue,
                                )
                            ) {
                                return space.spaceNumber === 0
                                    ? 0x000000
                                    : getGradientColorForConclusion(
                                          datapoint?.temperatures!,
                                          averageTemperatures,
                                          referenceTemperature,
                                          gradientKey,
                                          gradientKeyForHumidity!,
                                          humidityData!,
                                          humidityValue!,
                                          tolerance,
                                      );
                            }
                            return viewMode &&
                                !viewMode.some(
                                    (option) =>
                                        option.toLowerCase() === 'temperature',
                                )
                                ? space.spaceNumber === 0
                                    ? 0x000000
                                    : 0xffffff
                                : getColorFromGradient();
                        };

                        const createDashedLineMaterial = () => {
                            const dashedLinesForWalls = createDashedLinesForWalls(
                                spaceSizeX - 0.001,
                                floor.height - 0.001,
                                spaceSizeY - 0.001,
                            );

                            // Create a BufferGeometry to hold all line segments
                            const mergedGeometry = new THREE.BufferGeometry();

                            // Create arrays to hold positions and indices for merged geometry
                            const positions: any = [];
                            const indices: number[] = [];

                            // Iterate through each line segment and merge them into the geometry
                            dashedLinesForWalls.forEach(([start, end]: any) => {
                                const startIndex = positions.length / 3; // Get the starting index for the current line segment

                                // Push start and end points to positions array
                                positions.push(start[0], start[1], start[2]);
                                positions.push(end[0], end[1], end[2]);

                                // Push indices for the current line segment
                                indices.push(startIndex, startIndex + 1);
                            });

                            // Set positions attribute for the merged geometry
                            mergedGeometry.setAttribute(
                                'position',
                                new THREE.Float32BufferAttribute(positions, 3),
                            );

                            // Set indices for the merged geometry
                            mergedGeometry.setIndex(indices);

                            // Create material for the lines
                            const lineMaterial = new THREE.LineBasicMaterial({
                                color:
                                    value &&
                                    humidityData &&
                                    gradientKeyForHumidity &&
                                    getGradientColorForHumidity(
                                        humidityData,
                                        value,
                                        gradientKeyForHumidity,
                                    ),

                                linewidth: 5,
                                depthWrite: true,
                                depthTest: false,
                                transparent: true,
                            });

                            // Create LineSegments component to render the lines
                            const dashedLinesComponent = (
                                <primitive
                                    depthWrite={true}
                                    object={
                                        new THREE.LineSegments(
                                            mergedGeometry,
                                            lineMaterial,
                                        )
                                    }
                                />
                            );

                            return dashedLinesComponent;
                        };

                        const finalColor = getFinalColor(value);

                        const getNewOpacity = () => {
                            if (
                                viewMode &&
                                !!viewMode.some(
                                    (option) =>
                                        option.toLowerCase() === 'humidity',
                                )
                            ) {
                                return 0.1;
                            } else if (
                                viewMode &&
                                !!viewMode.some(
                                    (option) =>
                                        option.toLowerCase() === 'conclusion',
                                ) &&
                                space.spaceNumber !== 0
                            ) {
                                if (
                                    typeof finalColor === 'undefined' ||
                                    finalColor === '#389e0d'
                                ) {
                                    return 0.05;
                                }
                                return 0.8;
                            }
                            return opacity;
                        };
                        return (
                            <>
                                <Box
                                    // onPointerOver={(ev) => {
                                    //     if (datapoint?.id)
                                    //         onPointerOver(datapoint?.id, true);
                                    // }}
                                    // onPointerLeave={(ev) => {
                                    //     ev.stopPropagation();
                                    //     if (datapoint?.id)
                                    //         onPointerOver(datapoint?.id, false);
                                    // }}
                                    args={[
                                        // Small delta removes flimmer due to overlap
                                        spaceSizeX - 0.001,
                                        floor.height - 0.001,
                                        spaceSizeY - 0.001,
                                    ]}
                                    position={[
                                        spacePosX + spaceSizeX / 2,
                                        floorTotHeight + floor.height / 2,
                                        -(spacePosY + spaceSizeY / 2),
                                    ]}
                                    castShadow
                                    onClick={(ev) => {
                                        if (datapoint?.id) {
                                            selectedRealtimeSensors.includes(
                                                datapoint?.id,
                                            )
                                                ? dispatch(
                                                      deleteExistingRealtimeSensor(
                                                          datapoint?.id,
                                                      ),
                                                  )
                                                : dispatch(
                                                      addToExistingRealtimeSensor(
                                                          datapoint?.id,
                                                      ),
                                                  );
                                        }
                                        ev.stopPropagation();
                                    }}
                                    key={Math.random()}
                                >
                                    <meshLambertMaterial
                                        side={THREE.DoubleSide}
                                        color={finalColor}
                                        attach="material"
                                        transparent={true}
                                        opacity={
                                            datapoint?.id &&
                                            selectedRealtimeSensors.includes(
                                                datapoint?.id,
                                            )
                                                ? 1
                                                : getNewOpacity()
                                        }
                                        depthWrite={false}
                                        depthTest={false}
                                    />
                                    {viewMode &&
                                        !!viewMode.some(
                                            (option) =>
                                                option.toLowerCase() ===
                                                'humidity',
                                        ) &&
                                        space.spaceNumber !== 0 &&
                                        value &&
                                        //(isHumid || isDry) &&
                                        // createDashedLinesForWalls(
                                        //     spaceSizeX - 0.001,
                                        //     floor.height - 0.001,
                                        //     spaceSizeY - 0.001,
                                        // ).map(
                                        //     ([start, end]: any, index: any) => (
                                        //         value && <Line
                                        //             key={index}
                                        //             points={[start, end]}
                                        //             color={
                                        //                 value &&
                                        //                 humidityData &&
                                        //                 gradientKeyForHumidity
                                        //                     && getGradientColorForHumidity(
                                        //                           humidityData,
                                        //                           value,
                                        //                           gradientKeyForHumidity,
                                        //                       )
                                        //                     // : isHumid
                                        //                     // ? 0xff0000
                                        //                     // : 0x0000ff
                                        //             }
                                        //             dashSize={0.1}
                                        //             gapSize={0.1}
                                        //             depthWrite={false}
                                        //             depthTest={false}
                                        //             lineWidth={4}
                                        //         />
                                        //     ),
                                        // )}
                                        createDashedLineMaterial()}
                                </Box>
                                {shouldIndexRender &&
                                    (floor.floorNumber === 10 ||
                                        floor.floorNumber ===
                                            minFloorNumber) && (
                                        <>
                                            <mesh
                                                position={[
                                                    blockLength / 2,
                                                    0.1,
                                                    8.5,
                                                ]}
                                                rotation={[
                                                    0,
                                                    THREE.MathUtils.degToRad(
                                                        direction,
                                                    ),
                                                    Math.PI / 2,
                                                ]}
                                            >
                                                <ArrowWithDirection color="gray" />
                                                {/* <Text
                                                position={[0, 3, 0]}
                                                rotation={[0, 0, -Math.PI / 2]}
                                                fontSize={0.8}
                                                color="black"
                                                //anchorX="center"
                                                //anchorY="middle"
                                            >
                                                S
                                            </Text> */}
                                            </mesh>
                                            <mesh
                                                position={[
                                                    blockLength / 2,
                                                    0.1,
                                                    8.5,
                                                ]}
                                                rotation={[
                                                    0,
                                                    THREE.MathUtils.degToRad(
                                                        direction + 180,
                                                    ), // Rotate by 180 degrees
                                                    Math.PI / 2,
                                                ]}
                                            >
                                                <ArrowWithDirection
                                                    color="red"
                                                    space={2.5}
                                                />
                                                {/* <Text
                                                position={[0, 5.25, 0]}
                                                rotation={[0, 0, -Math.PI / 2]}
                                                fontSize={0.8}
                                                color="black"
                                                //anchorX="center"
                                                //anchorY="middle"
                                            >
                                                N
                                            </Text> */}
                                            </mesh>
                                        </>
                                    )}
                            </>
                        );
                    })}
                </mesh>
                {(floor.floorNumber === 10 ||
                    floor.floorNumber === minFloorNumber) && (
                    <Text
                        color={'black'}
                        fontSize={2}
                        position={[blockLength / 2, 0, 2]}
                    >
                        {block.name}
                    </Text>
                )}
            </group>
        );
    },
);

function add(...nums: number[]) {
    let i = 0;
    for (const v of nums) i += v;

    return i;
}

function ChartObject(props: {
    object: V3D_Object;
    referenceTemperature?: number;
    selectedSensors: number[];
    tolerance?: number;
    center: any;
    gradientKey: GradientKey;
    humidityData?: HumidityData[];
    humidityLevel?: number;
    viewMode?: string[];
    onSensorSelected: (id: number, selected: boolean) => void;
    onPointerOver: (id: number, leave: boolean) => void;
    onObjectPointerOver?: (object?: V3D_Object) => void;
}) {
    const {
        object,
        referenceTemperature,
        onSensorSelected,
        selectedSensors,
        onObjectPointerOver,
        tolerance,
        center,
        gradientKey,
        humidityData,
        humidityLevel,
        viewMode,
    } = props;

    //get helping axises
    const cubeRef = useRef<any>();
    useHelper(cubeRef, THREE.BoxHelper, 'blue');

    const reference =
        referenceTemperature ??
        object.referenceTemperatures.pseudoDOT ??
        object.referenceTemperatures.dot ??
        0;

    //Use this to calibrate camera?
    // console.log("bounds: ", geolib.getBounds(latAndLong));
    //center of bounds
    // console.log("center: ", geolib.getCenterOfBounds(latAndLong));
    const indexToRender = Math.ceil((object.blocks.length - 1) / 2);

    return (
        <>
            {reference !== undefined &&
                object.blocks?.map((block, blockIndex) => {
                    return (
                        <group
                            onPointerOver={() => {
                                if (onObjectPointerOver) {
                                    onObjectPointerOver(object);
                                }
                            }}
                            onPointerOut={() => {
                                if (onObjectPointerOver) {
                                    onObjectPointerOver(undefined);
                                }
                            }}
                            key={block.id}
                        >
                            <mesh>
                                {block.floors?.map((floor, index) => {
                                    return (
                                        <RenderFloorModel
                                            //onPointerOver={onPointerOver}
                                            selectedSensors={selectedSensors}
                                            onSensorSelected={onSensorSelected}
                                            floorTotHeight={add(
                                                ...block.floors
                                                    .slice(0, index)
                                                    .map(
                                                        (floor) => floor.height,
                                                    ),
                                            )}
                                            floor={floor}
                                            templates={object.templates}
                                            block={block}
                                            shouldIndexRender={
                                                blockIndex === indexToRender
                                            }
                                            center={center}
                                            averageTemperatures={
                                                object.averageTemperatures
                                            }
                                            referenceTemperature={Math.round(
                                                reference,
                                            )}
                                            tolerance={tolerance}
                                            humidityData={humidityData}
                                            humidityLevel={humidityLevel}
                                            gradientKey={gradientKey}
                                            key={floor.floorId}
                                            viewMode={viewMode}
                                        />
                                    );
                                })}
                            </mesh>
                        </group>
                    );
                })}
        </>
    );
}

function Camera(props: { spin: boolean; numberOfBlocks: number }) {
    const { spin, numberOfBlocks } = props;
    const [spinRotation, setSpinRotation] = useState(0);
    const controlsRef = useRef<any>();

    const cameraSettings = useSelector(selectCameraSettings);
    const { camera } = useThree();
    const dispatch = useDispatch();

    useEffect(() => {
        const position = cameraSettings.position;
        const rotation = cameraSettings.rotation;
        const target = cameraSettings.target;
        if (cameraSettings) {
            camera.position.set(position[0], position[1], position[2]);
            camera.zoom = cameraSettings.zoom;
            camera.rotation.set(rotation[0], rotation[1], rotation[2]);
            camera.updateProjectionMatrix();

            //update the control's target
            if (controlsRef.current.target) {
                controlsRef.current.target.set(target[0], target[1], target[2]);
                controlsRef.current.update();
            }
        }
    }, [camera, cameraSettings]);

    const handleEnd = () => {
        const controls = controlsRef.current;
        if (controls) {
            const { x: tx, y: ty, z: tz } = controls.target;
            dispatch(
                setCameraSettings({
                    position: [
                        camera.position.x,
                        camera.position.y,
                        camera.position.z,
                    ],
                    zoom: camera.zoom,
                    rotation: [
                        camera.rotation.x,
                        camera.rotation.y,
                        camera.rotation.z,
                    ],
                    target: [tx, ty, tz],
                }),
            );
        }
    };

    useFrame(() => {
        if (camera.position.y < 10) camera.position.y = 10;

        if (spin) {
            camera.position.x = 100 * Math.cos(spinRotation);
            camera.position.z = -100 * Math.sin(spinRotation);
            setSpinRotation((spinRotation + 0.001) % (2 * Math.PI));
        }
    });

    return (
        <>
            <PerspectiveCamera
                makeDefault
                fov={numberOfBlocks > 2 ? 40 : 20}
                args={[45, 10, 1, 10000]}
                position={[100, 40, 0]}
            />
            <OrbitControls
                ref={controlsRef}
                makeDefault
                enableRotate={true}
                enableZoom={true}
                autoRotate={false}
                onEnd={handleEnd}
                enableDamping={false}
                dampingFactor={0.2}
            />
        </>
    );
}

function Chart(props: ChartProps) {
    const {
        objects,
        referenceTemperature,
        onPointerOver,
        onSensorSelected,
        onObjectPointerOver,
        selectedSensors,
        tolerance,
        continuousSpin,
        gradientKey = generateGradientKey(
            { high: 22, low: 20 },
            gradients.thermometer.mix,
        ),
        humidityData,
        humidityLevel,
        viewMode,
        height,
    } = props;

    //Get coordinates to determine the center
    let latAndLong: any = [];
    objects.forEach((object) => {
        latAndLong = [
            ...latAndLong,
            ...object.blocks.flatMap((block) => {
                return [
                    {
                        latitude: block.leftCornerLat,
                        longitude: block.leftCornerLong,
                    },
                    {
                        latitude: block.rightCornerLat,
                        longitude: block.rightCornerLong,
                    },
                ];
            }),
        ];
    });

    const center = geolib.getCenterOfBounds(latAndLong);

    function calculateTotalBlocks(objects: V3D_Object[]) {
        return objects.reduce((totalBlocks, object) => {
            // Assuming each object has a 'blocks' property that contains the number of blocks
            if (object.blocks !== undefined && !isNaN(object?.blocks.length)) {
                return totalBlocks + object.blocks.length;
            }
            return totalBlocks;
        }, 0);
    }

    return (
        <>
            <Canvas
                gl={{ preserveDrawingBuffer: true }}
                style={{
                    width: '100%',
                    height: height ? height : '40vh',
                }}
            >
                {/* <primitive object={new THREE.AxesHelper(500)} /> */}
                {/* Change camera to one that can walk around? */}
                <Camera
                    spin={continuousSpin}
                    numberOfBlocks={calculateTotalBlocks(objects)}
                />
                {/* <OrbitControls makeDefault enableRotate enableZoom onEnd={handleEnd} /> */}
                <ambientLight intensity={0.2} />
                <pointLight position={[500, 500, 500]} />
                {/* Renders the objects */}

                {/* <Box args={[1,1,1]} position={[0, 0.5, 0]}></Box> */}
                {objects.map((object) => {
                    return (
                        <ChartObject
                            onObjectPointerOver={onObjectPointerOver}
                            onPointerOver={onPointerOver}
                            onSensorSelected={onSensorSelected}
                            object={object}
                            center={center}
                            selectedSensors={selectedSensors}
                            referenceTemperature={referenceTemperature}
                            tolerance={tolerance}
                            gradientKey={gradientKey}
                            key={object.objectId}
                            humidityData={humidityData}
                            humidityLevel={humidityLevel}
                            viewMode={viewMode}
                        />
                    );
                })}
            </Canvas>
        </>
    );
}

export default Chart;
