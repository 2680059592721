import { Checkbox, Table, Typography, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface BalancingTableProps {
    sensorIds: number[];
    setSensorIds: (ids: number[]) => void;
    balanceFilter: 'ALL' | 'HOT' | 'COLD';
    threshold: number;
    onChange: (id: number, checked: boolean) => void;
    transformedData: any[];
}

function BalancingTable(props: BalancingTableProps) {
    const {
        sensorIds,
        setSensorIds,
        balanceFilter,
        onChange,
        threshold,
        transformedData,
    } = props;

    const { t } = useTranslation();

    const columns = [
        {
            title: t('Is active'),
            dataIndex: 'isActive',
            width: '6ch',
            render: (text: any, record: any, index: any) => {
                return (
                    <Checkbox
                        key={record.id}
                        defaultChecked={text}
                        onChange={(event) => {
                            event.stopPropagation();
                            onChange(record.id, event.target.checked);
                        }}
                    />
                );
            },
        },
        {
            title: t('Name'),
            dataIndex: 'shortSensorName',
            width: '21ch',
            sorter: (a: any, b: any) =>
                a.sensorName.localeCompare(b.sensorName),
            render: (text: any, record: any, index: any) => {
                return (
                    <div>
                        <Tooltip title={record.fullSensorName}>
                            <Typography.Text
                                style={{
                                    width: 'max-content',
                                }}
                            >
                                {text}
                            </Typography.Text>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: t('Temp'),
            width: '5ch',
            dataIndex: 'indoorTemp',
            sorter: (a: any, b: any) => a.indoorTemp - b.indoorTemp,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: t('Δ'),
            width: '5ch',
            dataIndex: 'deltaAverage',
            sorter: (a: any, b: any) => a.deltaAverage - b.deltaAverage,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: t('Min'),
            width: '5ch',
            dataIndex: 'indoorTempMin',
            sorter: (a: any, b: any) => a.indoorTempMin - b.indoorTempMin,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: t('Max'),
            width: '5ch',
            dataIndex: 'indoorTempMax',
            sorter: (a: any, b: any) => a.indoorTempMax - b.indoorTempMax,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            title: t('Mean'),
            width: '5ch',
            dataIndex: 'indoorTempAverage',
            sorter: (a: any, b: any) =>
                a.indoorTempAverage - b.indoorTempAverage,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            width: '6ch',
            title: t('Noise+'),
            dataIndex: 'noisePos',
            sorter: (a: any, b: any) => a.noisePos - b.noisePos,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            width: '6ch',
            title: t('Noise-'),
            dataIndex: 'noiseNeg',
            sorter: (a: any, b: any) => a.noiseNeg - b.noiseNeg,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
        {
            width: '6ch',
            title: t('RC%'),
            dataIndex: 'rc',
            sorter: (a: any, b: any) => a.rc - b.rc,
            render: (text: any) => (
                <div
                    style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        display: 'inline-block',
                    }}
                >
                    {text}
                </div>
            ),
        },
    ];

    return (
        <div className="h-full">
            <Table
                size="small"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            if (
                                (event.target as HTMLElement).classList.contains(
                                    'ant-checkbox-input',
                                )
                            ) {
                                return;
                            }

                            if (sensorIds.includes(record.id)) {
                                const temp = sensorIds.filter(
                                    (id) => id !== record.id,
                                );

                                setSensorIds(temp);
                            } else {
                                const temp = [...sensorIds, record.id];

                                setSensorIds(temp);
                            }
                        },
                    };
                }}
                pagination={false}
                rowClassName={(record) => {
                    if (balanceFilter === 'ALL') {
                        if (
                            parseFloat(record.deltaAverage) < 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-blue-200 cursor-pointer font-semibold'
                                : 'bg-blue-200 cursor-pointer';
                        }
                        if (
                            parseFloat(record.deltaAverage) > 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-red-200 cursor-pointer font-semibold'
                                : 'bg-red-200 cursor-pointer';
                        } else {
                            return sensorIds.includes(record.id)
                                ? 'bg-white cursor-pointer font-semibold'
                                : 'bg-white cursor-pointer';
                        }
                    }
                    if (balanceFilter === 'HOT') {
                        if (
                            parseFloat(record.deltaAverage) > 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-red-200 cursor-pointer font-semibold'
                                : 'bg-red-200 cursor-pointer';
                        } else {
                            return 'hidden';
                        }
                    }
                    if (balanceFilter === 'COLD') {
                        if (
                            parseFloat(record.deltaAverage) < 0 &&
                            parseFloat(record.deltaAverageAbs) >= threshold
                        ) {
                            return sensorIds.includes(record.id)
                                ? 'bg-blue-200 cursor-pointer font-semibold'
                                : 'bg-blue-200 cursor-pointer';
                        } else {
                            return 'hidden';
                        }
                    } else {
                        return sensorIds.includes(record.id)
                            ? 'cursor-pointer font-semibold'
                            : 'cursor-pointer';
                    }
                }}
                dataSource={transformedData}
                columns={columns}
                scroll={{ y: `calc(100vh - 250px)` }}
            />
        </div>
    );
}

export default BalancingTable;
