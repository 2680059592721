import { DatePicker, Radio, Button } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import useViewport from 'src/hooks/generic/useViewport';
import { useTranslation } from 'react-i18next';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib/radio';
import { setViewType } from 'src/store/reducers/datePickerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import { setRefreshFlag } from 'src/store/reducers/selectedObjectSlice';
import './RangePickerRealTime.scss';

interface DatePickerProps {
    period: { start: string; stop: string };
    isHotColdActive: boolean;
    isRealtime?: boolean;
    onChange: (value: { start: string; stop: string }) => void;
    setHotAndColdState: (value: boolean) => void;
    clearSensors: () => void;
    fetchHotAndColdAgain: () => void;
}

const RangePickerRealTime = (props: DatePickerProps) => {
    const {
        period,
        onChange,
        clearSensors,
        setHotAndColdState,
        isHotColdActive,
        isRealtime,
        fetchHotAndColdAgain,
    } = props;

    const dispatch = useDispatch();
    const { mode } = useViewport();
    const viewType = useSelector(
        (state: RootState) => state.datePicker.viewType,
    );

    const [startDate, setStartDate] = useState<string | undefined>(
        period.start,
    );

    const { t } = useTranslation();

    const handleArrowClickCalled = useRef<boolean>(false);

    const [stopDate, setStopDate] = useState<string | undefined>(period.stop);
    const [endOpen, setEndOpen] = useState(false);
    const dateFormat = 'YYYY-MM-DD';
    const disabledStartDate = (startDate: Moment) => {
        const endValue = moment(startDate, dateFormat);

        if (!startDate || !endValue) {
            return false;
        }
        return startDate.valueOf() > endValue.valueOf();
    };

    const disabledEndDate = (endDate: Moment) => {
        const startValue = moment(startDate, dateFormat);
        if (!endDate || !startValue) {
            return false;
        }
        return endDate.valueOf() < startValue.valueOf();
    };

    const handleStartOpenChange = (open: boolean) => {
        if (!open) {
            setEndOpen(true);
        }
    };

    const handleEndOpenChange = (open: boolean) => {
        setEndOpen(open);
    };

    const handleRadioChange = (e: RadioChangeEvent) => {
        const currViewType = e.target.value;
        if (currViewType === 'day') {
            const newStartDate = moment(stopDate || moment().endOf('day'))
                //.subtract(24, 'hours')
                .format(dateFormat);

            setStartDate(newStartDate);
            onChange({ start: newStartDate, stop: stopDate || '' });
        } else if (currViewType === 'week') {
            const newStartDate = moment(stopDate || moment().endOf('day'))
                .subtract(1, 'week')
                .format(dateFormat);

            setStartDate(newStartDate);
            onChange({ start: newStartDate, stop: stopDate || '' });
        } else if (currViewType === 'month') {
            const newStartDate = moment(stopDate || moment().endOf('day'))
                .subtract(1, 'month')
                .format(dateFormat);

            setStartDate(newStartDate);
            onChange({ start: newStartDate, stop: stopDate || '' });
        } else if (currViewType === 'year') {
            const newStartDate = moment(stopDate || moment().endOf('day'))
                .subtract(1, 'year')
                .format(dateFormat);

            setStartDate(newStartDate);
            onChange({ start: newStartDate, stop: stopDate || '' });
        }

        dispatch(
            setViewType(e.target.value as 'day' | 'week' | 'month' | 'year'),
        );
    };

    const activateHotAndCold = () => {
        dispatch(setRefreshFlag(true));
        setHotAndColdState(true);
        fetchHotAndColdAgain();
    };

    const handleArrowClick = (direction: 'forward' | 'backward') => {
        const increment = direction === 'forward' ? 1 : -1;

        if (viewType === 'day') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'day')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'day')
                    .format(dateFormat),
            );
        } else if (viewType === 'week') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'week')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'week')
                    .format(dateFormat),
            );
        } else if (viewType === 'month') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'month')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'month')
                    .format(dateFormat),
            );
        } else if (viewType === 'year') {
            setStartDate((prevStartDate) =>
                moment(prevStartDate, dateFormat)
                    .add(increment, 'year')
                    .format(dateFormat),
            );
            setStopDate((prevStopDate) =>
                moment(prevStopDate, dateFormat)
                    .add(increment, 'year')
                    .format(dateFormat),
            );
        }

        handleArrowClickCalled.current = true;
    };

    useEffect(() => {
        if (
            handleArrowClickCalled.current &&
            startDate !== undefined &&
            stopDate !== undefined
        ) {
            onChange({ start: startDate, stop: stopDate });
            handleArrowClickCalled.current = false;
        }
    }, [startDate, stopDate, onChange]);

    return (
        <div
            className={classNames({
                'w-full': true,
                flex: true,
                'items-center': true,
                'flex-wrap': true,
                'gap-y-3': mode !== 'mobile',
                'pb-3': mode !== 'mobile',
            })}
        >
            {!isRealtime && (
                <Button
                    icon={<ArrowLeftOutlined />}
                    onClick={() => handleArrowClick('backward')}
                />
            )}
            <DatePicker
                disabledDate={disabledStartDate}
                className="datepicker-mobile"
                format="YYYY-MM-DD"
                value={moment(startDate, dateFormat)}
                placeholder="Start"
                onBlur={(v: any) => {
                    if (startDate !== period.start && stopDate && startDate) {
                        onChange({ start: startDate, stop: stopDate });
                    }
                }}
                onChange={(v: any) => {
                    setStartDate(v.format(dateFormat));
                    setStopDate(v.add(1, viewType).format(dateFormat));
                }}
                onOpenChange={handleStartOpenChange}
            />
            {!isRealtime && (
                <>
                    <DatePicker
                        disabledDate={disabledEndDate}
                        className="datepicker-mobile"
                        format="YYYY-MM-DD"
                        value={moment(stopDate, dateFormat)}
                        placeholder="End"
                        onSelect={() => {
                            if (
                                stopDate !== period.stop &&
                                stopDate &&
                                startDate
                            ) {
                                onChange({ start: startDate, stop: stopDate });
                            }
                        }}
                        onChange={async (v: any) => {
                            setStopDate(v.format(dateFormat));
                            if (startDate && stopDate) {
                                onChange({
                                    start: startDate,
                                    stop: v.format(dateFormat),
                                });
                            }
                        }}
                        onBlur={(v: any) => {
                            if (
                                stopDate !== period.stop &&
                                stopDate &&
                                startDate
                            ) {
                                onChange({ start: startDate, stop: stopDate });
                            }
                        }}
                        defaultOpen={false}
                        open={endOpen}
                        onOpenChange={handleEndOpenChange}
                    />
                    <Button
                        icon={<ArrowRightOutlined />}
                        onClick={() => handleArrowClick('forward')}
                    />
                    <div
                        style={{
                            marginLeft: '10px',
                            marginTop: mode === 'mobile' ? '10px' : 0,
                            marginBottom: mode === 'mobile' ? '10px' : 0,
                        }}
                    >
                        <Radio.Group
                            onChange={handleRadioChange}
                            value={viewType}
                        >
                            <Radio.Button value="day">{t('Day')}</Radio.Button>
                            <Radio.Button value="week">
                                {t('Week')}
                            </Radio.Button>
                            <Radio.Button value="month">
                                {t('Month')}
                            </Radio.Button>
                            <Radio.Button value="year">
                                {t('Year')}
                            </Radio.Button>
                        </Radio.Group>
                    </div>
                    <div
                        style={{
                            marginLeft: '10px',
                            marginBottom: mode === 'mobile' ? '10px' : 0,
                        }}
                    >
                        {isHotColdActive ? (
                            <Button
                                type="primary"
                                ghost
                                onClick={activateHotAndCold}
                            >
                                {t('Hot & Cold')}
                            </Button>
                        ) : (
                            <Button type="default" onClick={activateHotAndCold}>
                                {t('Hot & Cold')}
                            </Button>
                        )}
                        <Button type="default" onClick={clearSensors}>
                            {t('Clear')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default RangePickerRealTime;
