import { useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { useObject } from 'src/hooks/objects/useObject';
import { useUser } from 'src/hooks/users/useUser';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import { SettingFilled } from '@ant-design/icons';
import { useSite } from 'src/hooks/sites/useSite';
import Nav from 'src/utils/nav/Nav';
import useViewport from 'src/hooks/generic/useViewport';
import {
    useQueryParams,
    withDefault,
    NumberParam,
    StringParam,
} from 'use-query-params';

import { useCorporation } from 'src/hooks/corporations/useCorporation';
import Header from 'src/views/generic/Header';

interface IProps {
    active: string;
}

export default function HeaderWithProps(props: IProps) {
    const location = useLocation();
    const { t } = useTranslation();
    const history = useNavigate();

    const [params] = useQueryParams({
        objectId: withDefault(NumberParam, -1),
        userId: withDefault(StringParam, ''),
        siteId: withDefault(NumberParam, -1),
        siteName: withDefault(StringParam, ''),
        index: withDefault(StringParam, '0'),
    });

    const userId =
        (location.pathname.includes('users')
            ? location.pathname.split('/').pop()
            : '') || '';

    const corporationId =
        (location.pathname.includes('corporations')
            ? location.pathname.split('/').pop()
            : '') || '';

    const objectIdParam =
        (location.pathname.includes('analytics')
            ? location.pathname.split('/').pop()
            : -1) || -1;

    const { data: user } = useUser(Number.parseInt(userId!));

    const { objectId, siteId } = params;
    const { data: object } = useObject(
        objectId !== -1 ? objectId : objectIdParam !== -1 ? objectIdParam : -1,
    );

    const { data: corporation } = useCorporation(
        Number.parseInt(corporationId!),
    );

    const userCorporations = useSelector(
        (state: RootState) => state.userCorporations.userCorporations,
    );

    const siteIdString =
        (location.pathname.includes('sites/objects')
            ? location.pathname.split('/').pop()
            : '') || '';
    const siteIdNumber = Number.parseInt(siteIdString!);
    const { data: site } = useSite(siteIdNumber);

    function getHeaderProps() {
        if (location.pathname === '/sites') {
            return {
                action: (
                    <>
                        <Button
                            type="primary"
                            onClick={() =>
                                history(
                                    Nav.build({
                                        path: '/manage-site',
                                        queryParams: {},
                                    }),
                                )
                            }
                            className="float-right"
                        >
                            {t('Add site')}
                        </Button>
                    </>
                ),
                path: [
                    {
                        name: t('Sites'),
                        href: Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: {
                                corporationId: userCorporations[0],
                            },
                            queryParams: {},
                        }),
                    },
                ],
            };
        } else if (location.pathname.includes('settings')) {
            return {
                path: [
                    {
                        name: t('Settings'),
                        href: Nav.build({
                            path: '/settings',
                            queryParams: {},
                        }),
                    },
                ],
            };
        } else if (location.pathname === '/manage-object') {
            return {
                path: [
                    {
                        name: t('Sites'),
                        href: Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: {
                                corporationId: userCorporations[0],
                            },
                            queryParams: {},
                        }),
                    },
                    {
                        name: params.siteName,
                        href: Nav.build({
                            path: '/sites/objects/:siteId',
                            routeParams: { siteId: siteId },
                            queryParams: {},
                        }),
                    },

                    {
                        name: object?.name || '',
                        href: Nav.build({
                            path: '/analytics/:objectId',
                            routeParams: { objectId: objectId ?? -1 },
                            queryParams: {},
                        }),
                    },
                ],
            };
        } else if (location.pathname === '/manage-site') {
            return {
                path: [
                    {
                        name: t('Sites'),
                        href: Nav.build({
                            path: '/sites',
                        }),
                    },
                    {
                        name: params.siteName || '',
                        href: Nav.build({
                            path: '/sites',
                        }),
                    },
                ],
            };
        } else if (location.pathname === '/create-site') {
            return {
                path: [
                    {
                        name: t('Create site'),
                        href: Nav.build({
                            path: '/sites',
                        }),
                    },
                ],
            };
        } else if (location.pathname.includes('users')) {
            return {
                path: [
                    {
                        name: t('Settings'),
                        href: Nav.build({
                            path: '/settings',
                            queryParams: {},
                        }),
                    },
                    {
                        name: t('{{name}}', { name: user?.name }),
                        href: Nav.build({
                            path: '/users/:userId',
                            routeParams: {
                                userId: Number.parseInt(userId!),
                            },
                        }),
                    },
                ],
            };
        } else if (location.pathname.includes('corporations')) {
            return {
                path: [
                    {
                        name: t('Settings'),
                        href: Nav.build({
                            path: '/settings',
                            queryParams: {},
                        }),
                    },
                    {
                        name: t('{{name}}', { name: corporation?.name }),
                        href: Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: {
                                corporationId: Number.parseInt(corporationId!),
                            },
                            queryParams: {},
                        }),
                    },
                ],
            };
        } else if (location.pathname.includes('integrations')) {
            return {
                path: [
                    {
                        name: t('Sites'),
                        href: Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: {
                                corporationId: userCorporations[0],
                            },
                            queryParams: {},
                        }),
                    },
                    {
                        name: object?.siteName ? object.siteName : '',
                        href: Nav.build({
                            path: '/sites/objects/:siteId',
                            routeParams: {
                                siteId: object?.siteId ? object.siteId : -1,
                            },
                            queryParams: {},
                        }),
                    },
                ],
            };
        } else if (location.pathname.includes('analytics')) {
            return {
                path: [
                    {
                        name: t('Sites'),
                        href: Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: {
                                corporationId: userCorporations[0],
                            },
                            queryParams: {},
                        }),
                    },
                    {
                        name: `${object?.siteName ?? ''}`,
                        href: Nav.build({
                            path: '/sites/objects/:siteId',
                            routeParams: {
                                siteId: Number.parseInt(
                                    `${object?.siteId ?? ''}`,
                                ),
                            },
                            queryParams: {},
                        }),
                    },

                    {
                        name: object?.name || '',
                        href: Nav.build({
                            path: '/analytics/:objectId',
                            routeParams: {
                                objectId: Number.parseInt(
                                    `${object?.id ?? ''}`,
                                ),
                            },
                            queryParams: {},
                        }),
                    },
                ],
            };
        } else if (location.pathname.includes('sites/objects')) {
            return {
                action: (
                    <div className="flex items-center gap-x-2">
                        <Button
                            type="primary"
                            onClick={() =>
                                history(
                                    Nav.build({
                                        path: '/manage-object',
                                        queryParams: {
                                            siteId: siteIdString,
                                        },
                                    }),
                                )
                            }
                            className="float-right"
                        >
                            {t('Add object')}
                        </Button>
                        {site && (
                            <SettingFilled
                                onClick={() =>
                                    history(
                                        Nav.build({
                                            path: '/manage-site',
                                            queryParams: {
                                                siteName: site.name,
                                                siteId: siteIdString,
                                            },
                                        }),
                                    )
                                }
                                className="text-xl cursor-pointer"
                            />
                        )}
                    </div>
                ),
                path: [
                    {
                        name: t('Sites'),
                        href: Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: {
                                corporationId: userCorporations[0],
                            },
                            queryParams: {},
                        }),
                    },
                    {
                        name: site?.name || '',
                        href: Nav.build({
                            path: '/corporations/:corporationId',
                            routeParams: {
                                corporationId: userCorporations[0],
                            },
                            queryParams: {},
                        }),
                    },
                ],
            };
        }

        return {}; // Default case, no additional props
    }

    const headerProps = getHeaderProps();

    const { mode } = useViewport();

    return mode === 'desktop' ? (
        <div className="flex w-full justify-center items-center flex-wrap flex-col">
            <div
                className="grid flex-1"
                style={{
                    gridTemplateColumns:
                        mode === 'desktop' ? 'minmax(110ch, 55vw) 1fr' : '1fr',
                    paddingRight: '3rem',
                }}
            >
                <Header {...headerProps} />
            </div>
        </div>
    ) : (
        <Header {...headerProps} />
    );
}
