// avgObjTemperaturesSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

interface AvgObjTemperaturesState {
    [key: string]: any[];
}

const initialState: AvgObjTemperaturesState = {};

export const avgObjTemperaturesSlice = createSlice({
    name: 'avgObjTemperatures',
    initialState,
    reducers: {
        setAvgObjTemperatures: (
            state,
            action: PayloadAction<{ key: string; data: any[] }>,
        ) => {
            state[action.payload.key] = action.payload.data;
        },
    },
});

export const selectAvgObjTemperatures = (
    objectId: number,
    start: string,
    end: string,
) => (state: RootState) =>
    state.avgObjTemperatures[`${objectId}-${start}-${end}`];

export const { setAvgObjTemperatures } = avgObjTemperaturesSlice.actions;
export default avgObjTemperaturesSlice.reducer;
