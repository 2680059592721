// src/hooks/use3DData.ts
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/rootReducer';
import {
    startLoading,
    setData,
    setError,
} from 'src/store/reducers/visualizationSlice';
import { API } from 'src/api/API';

const use3DData = (objectId: number, stateId: number) => {
    const dispatch = useDispatch();

    // Access the cache, isLoading, and error from the Redux store
    const { cache, isLoading, error } = useSelector(
        (state: RootState) => state.visualization,
    );
    const data = cache[`${objectId}:${stateId}`];

    useEffect(() => {
        if (data !== undefined) {
            return;
        }

        dispatch(startLoading());

        new API()
            .fetchObjectVisualization(objectId, stateId)
            .then((data: any) => {
                dispatch(setData({ objectId, stateId, data }));
            })
            .catch((e) => {
                dispatch(setError(e.message));
            });
    }, [dispatch, objectId, stateId, data]);

    return { data, error, isLoading };
};

export default use3DData;
