// src/slices/dateSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { formatDate } from 'src/utils/formatDate';
import { RootState } from 'src/store/rootReducer';

interface DateState {
    start: string;
    stop: string;
}

const initialDate = new Date();
const initialState: DateState = {
    start: formatDate(
        new Date(
            new Date().setFullYear(
                initialDate.getFullYear(),
                initialDate.getMonth(),
                initialDate.getDate() - 7,
            ),
        ),
    ),
    stop: formatDate(initialDate),
};

const dateSlice = createSlice({
    name: 'date',
    initialState,
    reducers: {
        setStartDate(state, action: PayloadAction<string>) {
            state.start = action.payload;
        },
        setStopDate(state, action: PayloadAction<string>) {
            state.stop = action.payload;
        },
        setPeriod(
            state,
            action: PayloadAction<{ start: string; stop: string }>,
        ) {
            state.start = action.payload.start;
            state.stop = action.payload.stop;
        },
    },
});

export const selectDate = (state: RootState) => state.date;

export const { setStartDate, setStopDate, setPeriod } = dateSlice.actions;
export default dateSlice.reducer;
