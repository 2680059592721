import React, { useState, useEffect } from 'react';
import useViewport from 'src/hooks/generic/useViewport';
import { Tabs, Collapse, Divider } from 'antd';
import './styles.css';
import { NumberParam, withDefault, useQueryParams } from 'use-query-params';

interface MobileMenuProps {
    onClick: (index: number) => void;
    tabs: React.ReactNode[];
    activeIndex: number;
    isOpen: number;
    extra?: React.ReactNode[];
}
function MobileMenu(props: MobileMenuProps) {
    const { Panel } = Collapse;
    const { onClick, tabs, activeIndex, isOpen, extra } = props;

    return (
        <>
            <div className="absolute w-screen z-10" style={{ zIndex: 1000 }}>
                <Collapse
                    key={isOpen}
                    expandIconPosition="right"
                    style={{ backgroundColor: 'white' }}
                    bordered={false}
                >
                    <Panel
                        header={
                            <div className="flex pl-1">{tabs[activeIndex]}</div>
                        }
                        key={'1'}
                    >
                        <div className="">
                            {tabs.map((v, i) => {
                                if (i !== activeIndex)
                                    return (
                                        <div>
                                            <div
                                                onClick={() => onClick(i)}
                                                className="flex p-5"
                                            >
                                                {v}
                                            </div>
                                        </div>
                                    );
                                else return null;
                            })}
                        </div>

                        {extra && (
                            <>
                                <Divider />
                                {extra?.map((v) => (
                                    <div className="p-2">{v}</div>
                                ))}
                            </>
                        )}
                    </Panel>
                </Collapse>
            </div>
        </>
    );
}

export interface TabChild {
    body: React.ReactNode;

    tab: React.ReactNode;
}

export interface TabContainerProps {
    children: TabChild[];
    extra?: React.ReactNode[];
    activeTabIndex?: number;
    setActiveTabIndex?: (index: number) => void;
}

function TabContainer(props: TabContainerProps) {
    const { children, extra, activeTabIndex, setActiveTabIndex } = props;
    const [params, setParams] = useQueryParams({
        index: withDefault(NumberParam, 0),
    });
    const { mode } = useViewport();
    const { TabPane } = Tabs;
    const [isOpen, setIsOpen] = useState(0);

    const [renderedTabs, setRenderedTabs] = useState<
        Record<number | string, boolean>
    >({});

    useEffect(() => {
        if (activeTabIndex !== undefined) {
            setParams({ index: activeTabIndex });
        }
    }, [activeTabIndex, setParams]);

    useEffect(() => {
        // Mark the active tab as rendered
        setRenderedTabs((prev) => ({
            ...prev,
            [params.index]: true,
        }));
    }, [params.index]);

    if (mode === 'mobile') {
        return (
            <div className="flex">
                <div className="w-full h-full flex-grow">
                    <MobileMenu
                        extra={extra?.map((v) => v)}
                        isOpen={isOpen}
                        activeIndex={
                            activeTabIndex !== undefined
                                ? activeTabIndex
                                : params.index
                        }
                        onClick={(index: number) => {
                            setParams({ index: index });
                            setIsOpen(isOpen + 1);
                            if (setActiveTabIndex) {
                                setActiveTabIndex(index);
                            }
                        }}
                        tabs={children?.map((v) => v.tab)}
                    />
                    <div className="pt-16">
                        {children.map((child, index) => (
                            <div
                                key={index}
                                style={{
                                    display:
                                        //eslint-disable-next-line
                                        index == params.index
                                            ? 'block'
                                            : 'none',
                                }}
                            >
                                {/* Only render the body once and keep it in DOM and make the approach better */}
                                {renderedTabs[index] ? child.body : null}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="w-full h-full">
                <Tabs
                    defaultActiveKey={params.index.toString()}
                    activeKey={
                        activeTabIndex !== undefined
                            ? activeTabIndex.toString()
                            : undefined
                    }
                    tabBarExtraContent={extra?.[0]}
                    onChange={(v: any) => {
                        setParams({ index: v });
                        if (setActiveTabIndex) {
                            setActiveTabIndex(v);
                        }
                    }}
                    className="h-full"
                >
                    {children?.map((child, i) => {
                        return (
                            <TabPane key={i} tab={child.tab} className="flex-1">
                                {child.body}
                            </TabPane>
                        );
                    })}
                </Tabs>
            </div>
        );
    }
}

export default TabContainer;
