import background from 'src/assets/LoginBackground.svg';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Nav from 'src/utils/nav/Nav';
import { message } from 'antd';
import { useSignOut } from 'src/hooks/users/useSignOut';

export default function SignOut() {
    const history = useNavigate();
    const [signOut] = useSignOut();

    useEffect(() => {
        signOut().then(() => {
            message.success(
                'Sign out successful. Please redirect or reload the page',
            );
            history(Nav.build({ path: '/login' }));
        });
        //eslint-disable-next-line
    }, []);

    return (
        <div
            className="flex h-screen w-screen"
            style={{
                backgroundSize: 'cover',
                backgroundImage: `url(${background})`,
            }}
        >
            <div className="w-screen h-screen  md:h-auto sm:w-96 m-auto">
                <div>Signing out...</div>
            </div>
        </div>
    );
}
