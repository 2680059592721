// selectedRealtimeSensorsSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

type SelectedSensorsState = number[];

const initialState: SelectedSensorsState = [];

export const selectedRealtimeSensorsSlice = createSlice({
    name: 'selectedRealtimeSensors',
    initialState,
    reducers: {
        setSelectedRealtimeSensors: (
            state,
            action: PayloadAction<number[]>,
        ) => {
            return [...action.payload];
        },

        addToExistingRealtimeSensor: (state, action: PayloadAction<number>) => {
            const selectedSensorsSet = new Set(state);
            selectedSensorsSet.add(action.payload);
            return Array.from(selectedSensorsSet);
        },

        addToExistingRealtimeSensors: (
            state,
            action: PayloadAction<number[]>,
        ) => {
            const selectedSensorsSet = new Set(state);
            action.payload.forEach((sensor) => selectedSensorsSet.add(sensor));
            return Array.from(selectedSensorsSet);
        },

        deleteExistingRealtimeSensor: (
            state,
            action: PayloadAction<number>,
        ) => {
            const selectedSensorsSet = new Set(state);
            selectedSensorsSet.delete(action.payload);
            return Array.from(selectedSensorsSet);
        },
    },
});

export const selectRealtimeSensors = (state: RootState) =>
    state.selectedRealtimeSensors;

export const {
    setSelectedRealtimeSensors,
    addToExistingRealtimeSensor,
    addToExistingRealtimeSensors,
    deleteExistingRealtimeSensor,
} = selectedRealtimeSensorsSlice.actions;
export default selectedRealtimeSensorsSlice.reducer;
