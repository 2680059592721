import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

export type CameraSettingsState = {
    position: [number, number, number];
    zoom: number;
    rotation: [number, number, number];
    target: [number, number, number];
};

const initialState: CameraSettingsState = {
    position: [100, 40, 0],
    zoom: 1,
    rotation: [0, 0, 0],
    target: [0, 0, 0],
};

export const cameraSettingsSlice = createSlice({
    name: 'cameraSettings',
    initialState,
    reducers: {
        setCameraPosition: (
            state,
            action: PayloadAction<[number, number, number]>,
        ) => {
            state.position = action.payload;
        },
        setCameraFov: (state, action: PayloadAction<number>) => {
            state.zoom = action.payload;
        },
        setCameraRotation: (
            state,
            action: PayloadAction<[number, number, number]>,
        ) => {
            state.rotation = action.payload;
        },
        setCameraSettings: (
            state,
            action: PayloadAction<CameraSettingsState>,
        ) => {
            state.position = action.payload.position;
            state.zoom = action.payload.zoom;
            state.rotation = action.payload.rotation;
            state.target = action.payload.target;
        },
    },
});

export const selectCameraSettings = (state: RootState) => state.cameraSettings;

// Slice action creators
export const {
    setCameraPosition,
    setCameraFov,
    setCameraRotation,
    setCameraSettings,
} = cameraSettingsSlice.actions;

export default cameraSettingsSlice.reducer;
