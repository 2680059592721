import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Col, Row, Statistic } from 'antd';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import useViewport from 'src/hooks/generic/useViewport';
import { useDesiredTemperatures } from 'src/hooks/objects/useDesiredTemperatures';
import { useObject } from 'src/hooks/objects/useObject';
import { useReference } from 'src/hooks/objects/useReference';
import { useEnergyConsumption } from 'src/hooks/sites/useEnergyConsumption';
import { Period } from 'src/models/EnergyResponse';

interface IProps {
    objectId: number;
    stateId: number;
}

const formatDate = (ms: number) => {
    const date = new Date(ms);
    const month =
        date.getMonth() + 1 > 9
            ? (date.getMonth() + 1).toString()
            : '0' + (date.getMonth() + 1).toString();
    const day =
        date.getDate() > 9
            ? date.getDate().toString()
            : '0' + date.getDate().toString();

    return `${date.getFullYear()}-${month}-${day}`;
};

interface AdjustmentProps {
    percentSaved: number;
    reference: Period;
    objectId: number;
}

function Adjustment(props: AdjustmentProps) {
    const { percentSaved, reference, objectId } = props;

    const { t } = useTranslation();
    const { mode } = useViewport();
    const start = formatDate(reference.start);
    const stop = formatDate(reference.stop);
    const { data: energyConsumption } = useEnergyConsumption(
        objectId,
        start,
        stop,
    );

    return (
        <Col span={12}>
            {energyConsumption && (
                <Statistic
                    groupSeparator=" "
                    title={t('Changes')}
                    value={
                        percentSaved *
                        energyConsumption.reduce((s, c) => c.data.total + s, 0)
                    }
                    precision={0}
                    valueStyle={{
                        color: percentSaved > 0 ? '#51baa2' : '#889DA7',
                        fontSize: mode === 'desktop' ? '40px' : '18px',
                    }}
                    prefix={
                        percentSaved > 0 ? (
                            <ArrowDownOutlined
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                        ) : (
                            <ArrowUpOutlined
                                style={{
                                    verticalAlign: 'middle',
                                }}
                            />
                        )
                    }
                    suffix={energyConsumption[0]?.unit}
                />
            )}
        </Col>
    );
}

function TotalSaved(props: IProps) {
    const { objectId, stateId } = props;
    const { data: object } = useObject(objectId);
    const { t } = useTranslation();
    const { data: desiredTemperatures } = useDesiredTemperatures(
        objectId,
        stateId,
    );
    const { data: reference } = useReference(objectId);
    const { mode: screen } = useViewport();

    const percentSaved = object?.potentialSavings;

    return (
        <>
            {desiredTemperatures && desiredTemperatures?.data.length > 0 && (
                <Row
                    className="w-full mb-8"
                    style={{
                        marginBottom: screen === 'desktop' ? '2rem' : '4rem',
                    }}
                    justify="center"
                >
                    {percentSaved !== undefined && (
                        <Col span={12}>
                            <Statistic
                                groupSeparator=" "
                                title={t('Changes')}
                                value={(percentSaved * 100).toFixed(1)}
                                precision={1}
                                valueStyle={{
                                    color:
                                        percentSaved > 0
                                            ? '#51baa2'
                                            : '#889DA7',
                                    fontSize:
                                        screen === 'desktop' ? '40px' : '18px',
                                }}
                                prefix={
                                    percentSaved > 0 ? (
                                        <ArrowDownOutlined
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    ) : (
                                        <ArrowUpOutlined
                                            style={{
                                                verticalAlign: 'middle',
                                            }}
                                        />
                                    )
                                }
                                suffix={'%'}
                            />
                        </Col>
                    )}
                    {reference?.[0] && percentSaved !== undefined && (
                        <Suspense fallback={<></>}>
                            <Adjustment
                                reference={reference[0]}
                                percentSaved={percentSaved}
                                objectId={objectId}
                            />
                        </Suspense>
                    )}
                </Row>
            )}
        </>
    );
}

export default TotalSaved;
