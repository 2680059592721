// realtimeRawTemperaturesSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

interface RealtimeRawTemperaturesState {
    [key: string]: any[];
}

const initialState: RealtimeRawTemperaturesState = {};

export const realtimeRawTemperaturesSlice = createSlice({
    name: 'realtimeRawTemperatures',
    initialState,
    reducers: {
        setRealtimeRawTemperatures: (
            state,
            action: PayloadAction<{ key: string; data: any[] }>,
        ) => {
            state[action.payload.key] = action.payload.data;
        },
    },
});

export const selectRealtimeRawTemperatures = (
    objectId: number,
    start: string,
    end: string,
) => (state: RootState) =>
    state.realtimeRawTemperatures[`${objectId}-${start}-${end}`];

export const {
    setRealtimeRawTemperatures,
} = realtimeRawTemperaturesSlice.actions;
export default realtimeRawTemperaturesSlice.reducer;
