import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { BalanceData } from 'src/views/objects/Balancing/BalancingView';

export const useBalance = (
    id: number,
    start?: string,
    stop?: string,
    controlStateId?: number,
) => {
    const [cache, setCache] = useState<Record<number, BalanceData[]>>({});
    // state with data
    const [data, setData] = useState<BalanceData[]>([]);
    // state with route

    // use effect when route changes

    useEffect(() => {
        if (controlStateId === undefined) {
            return;
        }
        if (controlStateId in cache) {
            setData((cache as Record<number, BalanceData[]>)[controlStateId]);
        } else {
            (async () => {
                if (
                    id !== undefined &&
                    start !== undefined &&
                    stop !== undefined
                ) {
                    new API()
                        .fetchBalancingData(id, start, stop, controlStateId)
                        .then((data) => {
                            setCache({ ...cache, [controlStateId]: data });
                            setData(data);
                        });
                }
            })();
        }
    }, [cache, controlStateId, id, start, stop]);

    return { data };
};
