// src/slices/visualizationSlice.js
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { V3D_Object } from 'src/components/House3D/3d/chart/types';

interface CacheObject {
    [key: string]: V3D_Object; // Define the structure of the cache object (key-value pairs)
}

interface VisualizationState {
    cache: CacheObject;
    isLoading: boolean;
    error: string | null;
}

// Define the initial state
const initialState: VisualizationState = {
    cache: {}, // Cache stores V3D_Object keyed by `${objectId}:${stateId}`
    isLoading: false,
    error: null,
};

const visualizationSlice = createSlice({
    name: 'visualization',
    initialState,
    reducers: {
        // Start loading action
        startLoading(state: VisualizationState) {
            state.isLoading = true;
            state.error = null;
        },
        // Set data action
        setData(
            state: VisualizationState,
            action: PayloadAction<{
                objectId: number;
                stateId: number;
                data: V3D_Object;
            }>,
        ) {
            const { objectId, stateId, data } = action.payload;
            // Save the data in cache with `${objectId}:${stateId}` as the key
            state.cache[`${objectId}:${stateId}`] = data;
            state.isLoading = false;
        },
        setError(state: VisualizationState, action: PayloadAction<string>) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

export const { startLoading, setData, setError } = visualizationSlice.actions;

export default visualizationSlice.reducer;
