import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { HumidityData } from 'src/views/objects/Balancing/BalancingView';

export const useHumidity = (objectId: number, controlStateId?: number) => {
    const [humidityCache, setHumidityCache] = useState<
        Record<number, HumidityData[]>
    >({});
    // state with data
    const [data, setData] = useState<HumidityData[]>([]);
    // state with route

    // use effect when route changes

    useEffect(() => {
        if (controlStateId === undefined) {
            return;
        }
        if (controlStateId in humidityCache) {
            setData(
                (humidityCache as Record<number, HumidityData[]>)[
                    controlStateId
                ],
            );
        } else {
            (async () => {
                if (objectId !== undefined) {
                    new API()
                        .fetchHumidityData(objectId, controlStateId)
                        .then((humiditydata) => {
                            const { humidity } = humiditydata;
                            if (humiditydata) {
                                setHumidityCache({
                                    ...humidity,
                                    [controlStateId]: humidity.data,
                                });
                                setData(humidity.data);
                            }
                        });
                }
            })();
        }
    }, [humidityCache, controlStateId, objectId]);

    return { data };
};
