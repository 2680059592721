// avgObjTemperaturesSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

interface SensorAverageState {
    [key: string]: any[];
}

const initialState: SensorAverageState = {};

export const sensorAverageSlice = createSlice({
    name: 'sensorAverage',
    initialState,
    reducers: {
        setSensorAverage: (
            state,
            action: PayloadAction<{ key: string; data: any[] }>,
        ) => {
            state[action.payload.key] = action.payload.data;
        },
    },
});

export const selectSensorAverage = (key: string) => (state: RootState) =>
    state.sensorAverage[`${key}`];

export const { setSensorAverage } = sensorAverageSlice.actions;
export default sensorAverageSlice.reducer;
