import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Nav from 'src/utils/nav/Nav';
import { useNavigate } from 'react-router';
import useViewport from 'src/hooks/generic/useViewport';
import { Site } from 'src/models/Site';

interface MapProps {
    sites: Site[];
    searchQuery: string;
    setSearchQuery: (query: string) => void;
}

function SearchView(props: MapProps) {
    const { sites, searchQuery, setSearchQuery } = props;
    const history = useNavigate();

    const { mode } = useViewport();
    const { t } = useTranslation();

    const allSuggestions = sites.reduce<{ id: number; name: string }[]>(
        (current, site) => {
            site.objects?.forEach((obj) => {
                if (obj) {
                    current.push({
                        id: obj.id,
                        name: `${site.name?.toLowerCase()} - ${obj.name?.toLowerCase()}`,
                    });
                }
            });
            return current;
        },
        [],
    );

    const handleSearch = (value: string) => {
        setSearchQuery(value);
    };

    const handleSelect = (value: string) => {
        const objectId = allSuggestions.find((item) => item.name === value)?.id;
        history(
            Nav.build({
                path: '/analytics/:objectId',
                routeParams: { objectId: objectId ?? -1 },
                queryParams: {},
            }),
        );
        setSearchQuery(value);
    };

    return (
        <>
            <div
                id="search-sites"
                style={{
                    height: mode === 'mobile' ? '100%' : 'auto',
                    padding: '24px 24px 0 24px',
                }}
            >
                <AutoComplete
                    // options={suggestions.map((suggestion) => ({
                    //     value: suggestion.name,
                    //     label: suggestion.name,
                    // }))}
                    value={searchQuery}
                    onSelect={handleSelect}
                    onSearch={handleSearch}
                    onChange={setSearchQuery}
                    style={{ width: '100%', marginBottom: '16px' }}
                >
                    <Input
                        prefix={<SearchOutlined />}
                        placeholder={t('Search')}
                        allowClear
                    />
                </AutoComplete>
            </div>
        </>
    );
}

export default SearchView;
