import { useEffect, useState } from 'react';
import { API } from 'src/api/API';
import { TemperatureWithControlState } from 'src/models/Temperature';
import { selectRealtimeSensors } from 'src/store/reducers/selectedRealtimeSensorsSlice';
import {
    selectSensorAverage,
    setSensorAverage,
} from 'src/store/reducers/sensorAverageSlice';
import { useSelector, useDispatch } from 'react-redux';

export interface SensorWithAverage extends TemperatureWithControlState {
    sensorName: string;
    sensorId: number;
    underlyingSensorId: number;
}

const useSensorAverage = (objectId: number, stateId: number) => {
    const dispatch = useDispatch();

    // Local loading and error states
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const selectedRealtimeSensors = useSelector(selectRealtimeSensors);

    const key = `${objectId}-${stateId}-${selectedRealtimeSensors.length}`;

    // Access cache from Redux store
    const cachedData = useSelector(selectSensorAverage(key));

    useEffect(() => {
        // If cached data is available, do not fetch again
        if (cachedData !== undefined) {
            return;
        }

        // If no cached data, start loading and fetch new data
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);

            if (selectedRealtimeSensors && selectedRealtimeSensors.length) {
                const body = { sensors: selectedRealtimeSensors };
                const promise = new API().fetchMultipleSensorsWithAverage(
                    objectId,
                    stateId,
                    body,
                );

                promise
                    .then((sensorsAverages) => {
                        dispatch(
                            setSensorAverage({
                                key: `${objectId}-${stateId}-${selectedRealtimeSensors.length}`,
                                data: sensorsAverages,
                            }),
                        );
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching sensor averages:', error);
                        setError(error);
                    });
            }
        };

        fetchData();
    }, [dispatch, objectId, selectedRealtimeSensors, stateId, cachedData]);

    return { sensors: cachedData, isLoading, error, selectedRealtimeSensors };
};

export default useSensorAverage;
