// avgObjTemperaturesSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/rootReducer';

interface AverageTemperaturesControlState {
    [key: string]: any;
}

const initialState: AverageTemperaturesControlState = {};

export const averageTemperaturesControlStateSlice = createSlice({
    name: 'averageTemperaturesControlState',
    initialState,
    reducers: {
        setAverageTemperaturesControlState: (
            state,
            action: PayloadAction<{ key: string; data: any }>,
        ) => {
            state[action.payload.key] = action.payload.data;
        },
    },
});

export const selectAverageTemperaturesControlState = (key: string) => (
    state: RootState,
) => state.avgTemperaturesControlState[`${key}`];

export const {
    setAverageTemperaturesControlState,
} = averageTemperaturesControlStateSlice.actions;
export default averageTemperaturesControlStateSlice.reducer;
