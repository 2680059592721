import style from './Layout.module.scss';
import { ReactNode } from 'react';
import useViewport from 'src/hooks/generic/useViewport';

interface IProps {
    sidebar: ReactNode;
    header: ReactNode;
    children: ReactNode;
    topbar: ReactNode;
}

export default function Layout(props: IProps) {
    const { topbar, sidebar, header, children } = props;

    const { mode } = useViewport();

    return (
        <div className="overflow-hidden">
            <div className="shadow-sm" id={style.topbar}>
                {topbar}
            </div>
            <div id={style.container}>
                <div id={style['sidebar']}>{sidebar}</div>
                <div id={style['content-container']}>
                    <div id={style.header}>{header}</div>
                    <div className="flex w-full justify-center items-center flex-wrap flex-col">
                        <div
                            className="grid flex-1"
                            style={{
                                gridTemplateColumns:
                                    mode === 'desktop'
                                        ? 'minmax(110ch, 55vw) 1fr'
                                        : '1fr',
                                paddingRight: '3rem',
                            }}
                        >
                            <div id={style.content}>{children}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
