import { useEffect, useState } from 'react';
import { API } from 'src/api/API';

const cache: { [key: number]: any } = {};

export interface TreeDataNode {
    id: number;
    key: string;
    title: string;
    type: string;
    disabled: boolean;
    children?: TreeDataNode[];
}

const useObjectTree = (objectId: number) => {
    const [data, setData] = useState<TreeDataNode[]>(cache[objectId] || []);
    const [isLoading, setIsLoading] = useState(!cache[objectId]);

    useEffect(() => {
        if (cache[objectId]) {
            setData(cache[objectId]);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        new API()
            .fetchObjectTree(objectId)
            .then((myData: any) => {
                if (myData && myData.length) {
                    const newData = [];

                    for (let i = 0; i < myData.length; i++) {
                        newData.push(JSON.parse(myData[i]));
                    }

                    if (newData && newData.length) {
                        cache[objectId] = newData; // Cache the data based on objectId
                        setData(newData);
                    }
                }

                setIsLoading(false);
            })
            .catch((e) => {
                console.log(e.message);
                setIsLoading(false);
            });
    }, [objectId]);

    return { data, isLoading };
};

export default useObjectTree;
